<template>
    <v-dialog v-model="dialog" max-width="600px" @click:outside.prevent="hide" style="z-index: 9999999" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-account-plus-outline</v-icon>
                <div class="title-dialog">
                    <div class="title-subtext">
                        Crear Participante
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <kimsa-text-field
                            placeholder="Nombre"
                            outlined dense
                            force-value
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-text-field
                            placeholder="Email"
                            outlined dense
                            force-value
                            inputMode="email"
                            :value="email"
                            @change="email = $event"
                        />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="save" tile color="primary" depressed :disabled="disableSave" :loading="loading">
                            GUARDAR
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
export default {
    name: "CreateParticipantDialog",
    components: {KimsaTextField},
    props: {
        clientId: {
            type: [Number, String],
            default: null,
        },
    },
    data() {
        return {
            dialog: false,
            loading: false,
            name: '',
            email: '',
        }
    },
    computed: {
        disableSave(vm = this) {
            return !vm.validEmail(vm.email) || !vm.name
        }
    },
    methods: {
        async show() {
            const vm = this
            vm.dialog = true
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.$emit('hide')
            setTimeout(() => {
                vm.reset()
            },500)
        },
        reset() {
            const vm = this
            vm.name = ''
            vm.email = ''
        },
        validEmail(email) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(email) || false
        },
        async save() {
            const vm = this
            vm.loading = true

            let payload = {
                client_id: vm.clientId,
                name: vm.name,
                email: vm.email,
            }

            await Axios.post(`participant/new`, payload).then(res => {
                // console.log('res save',res)

                if(!res.data.result.ok) {
                    vm.toast(res.data.message, 'error')
                } else {
                    vm.toast('Participante creado')
                    vm.$emit('success', res.data.result.participant)
                }
            }).catch(error => {
                vm.toast(error, 'error')
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        }
    },
}
</script>

<style scoped>

</style>