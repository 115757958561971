<template>
    <div>
        <span v-if="label" :class="['label', {'bolder-label': bolderLabel}]">{{ label }}</span>
        <v-select
            v-model="model"
            :items="itemsAvailable"
            :menu-props="{ top: false, offsetY: true }"
            hide-details
            :solo="solo"
            :flat="flat"
            :outlined="outlined"
            :dense="dense"
            :placeholder="placeholder"
            :rules="rules"
            :clearable="clearable"
            :prepend-inner-icon="icon"
            :item-text="itemText"
            :item-value="itemValue"
            :return-object="returnObject"
            :readonly="readonly"
            :multiple="multiple"
            @change="onChange"
            :class="[{'group': group}]"
            ref="select"
        >
            <template v-if="multiple" v-slot:selection="{ item, index }">
                <div v-if="index === 0" class="text-truncate" style="width: 90%">
                    {{ item.name }}
                    <small v-if="model.length > 1">
                        (+{{ model.length - 1 }})
                    </small>
                </div>
            </template>
            <template v-else-if="withAvatarColor" v-slot:selection="{ item, index }">
                <v-avatar :color="item.color" size="15" class="mr-2"/>
                {{ item.name }}
            </template>
            <template v-else v-slot:selection="{ item, index }">
                {{ item.name }}
            </template>

            <template v-if="createParticipantButton" v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item link @click="createParticipant">
                    <v-list-item-avatar class="my-0">
                        <v-icon left>mdi-plus-circle-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>
                        Crear participante
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-select>
        <slot></slot>

        <create-participant-dialog v-if="createParticipantButton" :client-id="clientId" ref="createParticipant" @hide="onHide" @success="onSuccess(participant = $event)"/>
    </div>
</template>

<script>
import CreateParticipantDialog from "@/components/meeting/CreateParticipantDialog";
export default {
    name: "KimsaSelect",
    components: {CreateParticipantDialog},
    props: {
        label: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary'
        },
        rules: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        outlined: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: '',
        },
        itemValue: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number, Array, Object],
            default: '',
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        clearOnSelect: {
            type: Boolean,
            default: false,
        },
        currentSelection: {
            type: Array,
            default: () => []
        },
        forceValue: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        group: {
            type: Boolean,
            default: false
        },
        bolderLabel: {
            type: Boolean,
            default: false
        },
        withAvatarColor: {
            type: Boolean,
            default: false
        },
        createParticipantButton: {
            type: Boolean,
            default: false
        },
        clientId: {
            type: [Number, String],
            default: null,
        },
        clearable: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            model: '',
        }
    },
    computed: {
        itemsAvailable(vm = this) {
            if (vm.clearOnSelect && vm.currentSelection.length > 0) {
                return vm.items.filter(item => !vm.currentSelection.includes(item.id))
            }
            return vm.items
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        onChange() {
            const vm = this
            if (vm.clearOnSelect) {
                vm.$nextTick(() => {
                    vm.model = undefined
                })
            }
        },
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
        },
        createParticipant() {
            const vm = this
            vm.$refs.createParticipant.show()
        },
        onHide() {
            const vm = this
            vm.$refs.select.isFocused = true
            vm.$refs.select.isMenuActive = true
        },
        onSuccess(participant) {
            const vm = this
            vm.items.push(participant)
            vm.model.push(participant.id)
        }
    },
    watch: {
        model(val) {
            if (val !== undefined) {
                this.$emit('change', val)
            }
        },
        value() {
            this.setDefaults()
        },
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

.group {
    border-radius: 4px 0 0 4px;
}

.bolder-label {
    font-weight: bold;
}

</style>