var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('span',{class:['label', {'bolder-label': _vm.bolderLabel}]},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('v-select',{ref:"select",class:[{'group': _vm.group}],attrs:{"items":_vm.itemsAvailable,"menu-props":{ top: false, offsetY: true },"hide-details":"","solo":_vm.solo,"flat":_vm.flat,"outlined":_vm.outlined,"dense":_vm.dense,"placeholder":_vm.placeholder,"rules":_vm.rules,"clearable":_vm.clearable,"prepend-inner-icon":_vm.icon,"item-text":_vm.itemText,"item-value":_vm.itemValue,"return-object":_vm.returnObject,"readonly":_vm.readonly,"multiple":_vm.multiple},on:{"change":_vm.onChange},scopedSlots:_vm._u([(_vm.multiple)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('div',{staticClass:"text-truncate",staticStyle:{"width":"90%"}},[_vm._v(" "+_vm._s(item.name)+" "),(_vm.model.length > 1)?_c('small',[_vm._v(" (+"+_vm._s(_vm.model.length - 1)+") ")]):_vm._e()]):_vm._e()]}}:(_vm.withAvatarColor)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"color":item.color,"size":"15"}}),_vm._v(" "+_vm._s(item.name)+" ")]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},(_vm.createParticipantButton)?{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.createParticipant}},[_c('v-list-item-avatar',{staticClass:"my-0"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")])],1),_c('v-list-item-title',[_vm._v(" Crear participante ")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_vm._t("default"),(_vm.createParticipantButton)?_c('create-participant-dialog',{ref:"createParticipant",attrs:{"client-id":_vm.clientId},on:{"hide":_vm.onHide,"success":function($event){return _vm.onSuccess(_vm.participant = $event)}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }