<template>
    <v-dialog v-model="dialog" fullscreen @click:outside="hide">
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-chart-box-outline</v-icon>
                <div class="title-dialog">
                    {{ editMode ? 'Editar' : 'Crear' }} Estado Financiero
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <!-- HEADERS INPUTS -->
                <v-row class="py-5">
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Cliente"
                            outlined dense force-value
                            :value="client" readonly
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Año"
                            outlined dense force-value
                            :value="year" readonly
                        />
                    </v-col>

                    <v-col cols="12">
                        <monthly-dollar-input
                            title="Valor Dolar"
                            :values="dollar"
                            @change="dollar = $event"
                        />
                    </v-col>

                    <v-col cols="4" v-for="(title,i) in titles" :key="i" :class="['title-carousel', {'title-carousel-active': carousel >= i}]">
                        {{ i + 1 }}. {{ title }}
                        <div :class="['border-carousel', {'border-carousel-active': carousel >= i}]"></div>
                    </v-col>
                </v-row>
                <v-carousel
                    :show-arrows="false"
                    hide-delimiters
                    v-model="carousel"
                    touchless
                    height="100%"
                >
                    <!-- PAGE 1 -->
                    <v-carousel-item>
                        <v-row class="background-carousel">
                            <!-- INPUTS -->
                            <v-col cols="12">
                                <div class="title-carousel-item">
                                    ASSETS/ACTIVOS
                                </div>

                                <monthly-financial-values-input
                                    title="CURRENT ASSETS/ ACTIVOS CIRCULANTES"
                                    :template="template"
                                    :values="currentAssets"
                                    @change="currentAssets = $event"
                                />

                                <monthly-financial-values-input
                                    title="PROPERTY AND EQUIPMENT/ ACTIVOS FIJOS"
                                    :template="template"
                                    :values="fixedAssets"
                                    @change="fixedAssets = $event"
                                />

                                <monthly-financial-values-input
                                    title="OTHER ASSETS/ OTROS ACTIVOS"
                                    :template="template"
                                    :values="otherAssets"
                                    @change="otherAssets = $event"
                                />

                            </v-col>
                        </v-row>
                    </v-carousel-item>
                    <!-- PAGE 2 -->
                    <v-carousel-item>
                        <v-row class="background-carousel">
                            <!-- INPUTS -->
                            <v-col cols="12">
                                <div class="title-carousel-item">
                                    LIABILITIES AND MEMBERS' CAPITAL /PASIVOS Y PATRIMONIO
                                </div>

                                <monthly-financial-values-input
                                    title="CURRENT LIABILITIES/ PASIVOS CIRCULANTES"
                                    :template="template"
                                    name-label="Pasivos"
                                    :values="currentLiabilities"
                                    @change="currentLiabilities = $event"
                                />

                                <monthly-financial-values-input
                                    title="LONG-TERM DEBT / PASIVOS LARGO PLAZO"
                                    :template="template"
                                    name-label="Pasivos"
                                    :values="longTermLiabilities"
                                    @change="longTermLiabilities = $event"
                                />

                                <monthly-financial-values-input
                                    title="MEMBER'S CAPITAL/ CUENTAS DE PATRIMONIO"
                                    :template="template"
                                    name-label="Pasivos"
                                    :values="membersCapital"
                                    @change="membersCapital = $event"
                                />

                            </v-col>
                        </v-row>
                    </v-carousel-item>
                    <!-- PAGE 3 -->
                    <v-carousel-item>
                        <v-row class="background-carousel">
                            <!-- INPUTS -->
                            <v-col cols="12">
                                <div class="title-carousel-item">
                                    INCOME STATEMENT / ESTADO DE RESULTADOS
                                </div>

                                <monthly-financial-values-input
                                    title="REVENUE / INGRESOS"
                                    :template="template"
                                    name-label="Ingresos"
                                    :values="revenue"
                                    @change="revenue = $event"
                                    no-add-rows
                                />

                                <monthly-financial-values-input
                                    title="COST OF SALES / COSTO DE VENTAS"
                                    :template="template"
                                    name-label="Costos"
                                    :values="costSale"
                                    @change="costSale = $event"
                                    no-add-rows
                                />

                                <monthly-financial-values-input
                                    title="OPERATING EXPENSES / GASTOS OPERACIONALES"
                                    :template="template"
                                    name-label="Gastos"
                                    :values="operatingExpenses"
                                    @change="operatingExpenses = $event"
                                    no-add-rows
                                />

                                <monthly-financial-values-input
                                    title="OTHER EXPENSES/INCOMEN NOT OP/ OTROS GASTOS INGRE NO OPER."
                                    :template="template"
                                    name-label="Gastos"
                                    :values="otherExpenses"
                                    @change="otherExpenses = $event"
                                    no-add-rows
                                />

                            </v-col>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
                <!-- CONTINUE & BACK -->
                <div style="height: 50px"/>
                <v-row class="footer-dialog">
                    <v-btn v-if="carousel !== 0" @click="carousel--" color="grey" depressed tile class="white--text">
                        Anterior
                    </v-btn>
                    <v-spacer/>
                    <v-btn @click="continueStepper" color="primary" depressed tile :loading="loading">
                        {{ carousel === 2 ? 'Guardar Estado Financiero' : 'Continuar' }}
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import Axios from "@/utils/Axios";
import MonthlyDollarInput from "@/components/financial/MonthlyDollarInput";
import MonthlyFinancialValuesInput from "@/components/financial/MonthlyFinancialValuesInput";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";

export default {
    name: "CreateFinancialDialog",
    components: {KimsaTextField, MonthlyFinancialValuesInput, MonthlyDollarInput},
    data() {
        return {
            dialog: false,
            loading: false,
            editMode: false,
            budgetId: null,
            financial: null,
            carousel: 0,
            client: '',
            year: '',
            dollar: [],
            currentAssets: [],
            fixedAssets: [],
            otherAssets: [],
            currentLiabilities: [],
            longTermLiabilities: [],
            membersCapital: [],
            revenue: [],
            costSale: [],
            operatingExpenses: [],
            otherExpenses: [],
            template: {name: ''},
            titles: [
                'ASSETS/ ACTIVOS',
                'LIABILITIES/ PASIVOS',
                'INCOME/ RESULTADOS',
            ],
        }
    },
    computed: {
        ...mapState(['dialogActive']),
    },
    async mounted() {
        const vm = this
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show(payload) {
            const vm = this
            await vm.loadBasicInfo(payload)
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            },500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.carousel = 0
            vm.budgetId = null
            vm.financial = null
            vm.client = ''
            vm.year = ''
            vm.dollar = []
            vm.currentAssets = []
            vm.fixedAssets = []
            vm.otherAssets = []
            vm.currentLiabilities = []
            vm.longTermLiabilities = []
            vm.membersCapital = []
            vm.revenue = []
            vm.costSale = []
            vm.operatingExpenses = []
            vm.otherExpenses = []
        },
        async loadBasicInfo(payload) {
            const vm = this
            vm.budgetId = payload.budgetId
            vm.client = payload.client
            vm.year = payload.year
            if(payload.financialId) {
                await vm.loadFinancial(payload.financialId)
            } else {
                vm.revenue = vm.takeAccountNames(payload.revenue)
                vm.costSale = vm.takeAccountNames(payload.costSale)
                vm.operatingExpenses = vm.takeAccountNames(payload.operatingExpenses)
                vm.otherExpenses = vm.takeAccountNames(payload.otherExpenses)
            }
        },
        takeAccountNames(array) {
            const vm = this
            return JSON.parse(JSON.stringify(array)).map(ar => {
                return {name: ar.name}
            })
        },
        async loadFinancial(id) {
            const vm = this
            await Axios.get(`financial-statement/${id}`).then(res => {
                // console.log('res loadFinancial', res)
                vm.financial = res.data.result.financialStatement
                vm.editMode = true
                vm.dollar = vm.financial.dollar
                vm.currentAssets = vm.financial.current_assets
                vm.fixedAssets = vm.financial.fixed_assets
                vm.otherAssets = vm.financial.other_assets
                vm.currentLiabilities = vm.financial.current_liabilities
                vm.longTermLiabilities = vm.financial.long_term_liabilities
                vm.membersCapital = vm.financial.members_capital
                vm.revenue = vm.financial.revenue
                vm.costSale = vm.financial.cost_sale
                vm.operatingExpenses = vm.financial.operating_expenses
                vm.otherExpenses = vm.financial.other_expenses
            }).catch(er => {
                console.log('error loadFinancial', er)
            })
        },
        continueStepper() {
            const vm = this
            if (vm.carousel === 2) {
                if(vm.editMode) return vm.editFinancial()
                return vm.saveFinancial()
            }
            vm.carousel++
        },
        async saveFinancial() {
            const vm = this
            vm.loading = true
            // console.log('saveFinancial', vm)

            let payload = {
                budget_id: vm.budgetId,
                dollar: vm.dollar,
                current_assets: vm.currentAssets,
                fixed_assets: vm.fixedAssets,
                other_assets: vm.otherAssets,
                current_liabilities: vm.currentLiabilities,
                long_term_liabilities: vm.longTermLiabilities,
                members_capital: vm.membersCapital,
                revenue: vm.revenue,
                cost_sale: vm.costSale,
                operating_expenses: vm.operatingExpenses,
                other_expenses: vm.otherExpenses,
            }

            // console.log('payload', payload)

            await Axios.post('financial-statement/new', payload).then(res => {
                // console.log('res saveFinancial',res)
                vm.toast('Estado Financiero creado')
                vm.$emit('success')
            }).catch(error => {
                console.log('error saveFinancial', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editFinancial() {
            const vm = this
            vm.loading = true
            // console.log('editFinancial', vm)

            let payload = {
                budget_id: vm.budgetId,
                dollar: vm.dollar,
                current_assets: vm.currentAssets,
                fixed_assets: vm.fixedAssets,
                other_assets: vm.otherAssets,
                current_liabilities: vm.currentLiabilities,
                long_term_liabilities: vm.longTermLiabilities,
                members_capital: vm.membersCapital,
                revenue: vm.revenue,
                cost_sale: vm.costSale,
                operating_expenses: vm.operatingExpenses,
                other_expenses: vm.otherExpenses,
            }

            // console.log('payload', payload)

            await Axios.patch(`financial-statement/${vm.financial.id}/edit`, payload).then(res => {
                // console.log('res editFinancial',res)
                vm.toast('Estado Financiero actualizado')
                vm.$emit('success')
            }).catch(error => {
                console.log('error editFinancial', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

.title-carousel {
    font-size: 11px;
    color: #a0a0a0;
}

.title-carousel-active {
    font-size: 12px;
    color: var(--v-accent-base);
    font-weight: bold;
}

.border-carousel {
    color: #a0a0a0;
    border: 2px solid #a0a0a0;
    border-radius: 10px;
}

.border-carousel-active {
    color: var(--v-accent-base);
    border-color: var(--v-accent-base);
}

.background-carousel {
    background-color: #f9f9f9;
    border-radius: 5px;
    border: 1px solid #efefef;
    padding: 10px;
    margin: 0;
}

.title-carousel-item {
    color: var(--v-primary-base);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}

.label-financial {
    font-size: 10px;
    color: #212322;
}

.footer-dialog {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: auto;
    background-color: white;
    padding: 10px 42px;
}

</style>