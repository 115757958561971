<template>
    <div>
        <!-- TITLE -->
        <v-row class="mb-3" no-gutters align="center">
            <v-col cols="12" class="label">
                Valor Dolar por Mes
            </v-col>
        </v-row>

        <!-- INPUTS -->
        <v-row align="center" class="px-3">
            <v-col cols="1" v-for="month in months" :key="month.value" class="px-0">
                <kimsa-text-field
                    no-border
                    :input-label="month.name"
                    outlined dense force-value
                    :value="items[0][month.value]"
                    @change="items[0][month.value] = $event"
                    @blur="validNumber(items[0][month.value], month.value)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";

export default {
    name: "MonthlyDollarInput",
    components: {KimsaTextField},
    props: {
        values: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            items: [{}],
            months: [
                {name: 'Enero', value: 'january'},
                {name: 'Febrero', value: 'february'},
                {name: 'Marzo', value: 'march'},
                {name: 'Abril', value: 'april'},
                {name: 'Mayo', value: 'may'},
                {name: 'Junio', value: 'june'},
                {name: 'Julio', value: 'july'},
                {name: 'Agosto', value: 'august'},
                {name: 'Septiembre', value: 'september'},
                {name: 'Octubre', value: 'october'},
                {name: 'Noviembre', value: 'november'},
                {name: 'Diciembre', value: 'december'},
            ],
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.values && vm.values.length > 0 && JSON.stringify(vm.values) !== '[[]]') vm.items = vm.values
            else {
                vm.items = [{}]
            }
        },
        validNumber(value, attribute) {
            let newValue = 0
            if(!isNaN(Number(value))) newValue = Number(value)
            this.items[0][attribute] = newValue
            this.items = JSON.parse(JSON.stringify(this.items))
        },
    },
    watch: {
        items: {
            deep: true,
            handler() {this.$emit('change', this.items)}
        },
        values() {
            this.setDefaults()
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #212322;
    font-weight: bold;
}

</style>