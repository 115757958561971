<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" v-if="budget">
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-chart-box-outline</v-icon>
                <div class="title-dialog">
                    Descargar Estado Financiero
                    <div class="title-subtext">
                        Cliente: <b>{{ budget.client }}</b> | Año: <b>{{ budget.year }}</b>
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col :cols="needSetMonth ? 4 : 12">
                        <kimsa-select
                            label="Tipo"
                            placeholder="Selecciona un tipo de PDF"
                            bolder-label
                            :items="types"
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="value"
                            force-value
                            return-object
                            :value="type"
                            @change="type = $event"
                        />
                    </v-col>
                    <v-col cols="8" v-if="needSetMonth">
                        <kimsa-date-picker
                            label="Mes"
                            placeholder="Seleccionar Mes"
                            bolder-label
                            outlined solo
                            type="month"
                            flat dense
                            force-value
                            :value="month"
                            @change="month = $event"
                        />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="downloadPDF()" tile color="primary" depressed :loading="loading" :disabled="valid">
                            Descargar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import Axios from "@/utils/Axios";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";

export default {
    name: "FinancialPdfDownload",
    components: {
        KimsaSelect,
        KimsaDatePicker,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            budget: null,
            month: null,
            months: [
                {name: 'Enero', value: 'january'},
                {name: 'Febrero', value: 'february'},
                {name: 'Marzo', value: 'march'},
                {name: 'Abril', value: 'april'},
                {name: 'Mayo', value: 'may'},
                {name: 'Junio', value: 'june'},
                {name: 'Julio', value: 'july'},
                {name: 'Agosto', value: 'august'},
                {name: 'Septiembre', value: 'september'},
                {name: 'Octubre', value: 'october'},
                {name: 'Noviembre', value: 'november'},
                {name: 'Diciembre', value: 'december'},
            ],
            type: {name: 'Presupuesto', value: 'budget'},
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            return (vm.needSetMonth && !vm.month) || !vm.type
        },
        needSetMonth(vm = this) {
            return vm.type.value === 'balance' || vm.type.value === 'comparative'
        },
        types(vm = this) {
            if(vm.budget.financialId) {
                return [
                    {name: 'Presupuesto', value: 'budget'},
                    {name: 'Balance', value: 'balance'},
                    {name: 'Comparativo', value: 'comparative'},
                ]
            }
            return [{name: 'Presupuesto', value: 'budget'}]
        }
    },
    async mounted() {
        const vm = this
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show(budget) {
            const vm = this
            vm.budget = budget
            console.log('budget',budget)
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            }, 500)
        },
        reset() {
            const vm = this
            vm.budget = null
            vm.type = {name: 'Presupuesto', value: 'budget'}
        },
        async downloadPDF() {
            const vm = this
            console.log('downloadPDF', vm.budget)
            vm.loading = true

            let month = vm.months[Number(vm.month?.split('-')[1]) - 1]?.value || null
            if(!month && vm.needSetMonth) vm.toast('Sin mes seleccionado', 'error')

            let url = `budget/${vm.budget.id}/pdf`
            if(vm.type.value === 'comparative') url = `financial-statement/${vm.budget.id}/month/${month}/comparative/pdf`
            if(vm.type.value === 'balance') url = `financial-statement/${vm.budget.id}/month/${month}/balance/pdf`

            await Axios.get(url).then(res => {
                console.log('res pdfItem',res)
                const pdf = res.data.result.pdf
                const linkSource = `data:application/pdf;base64,${pdf}`;
                const downloadLink = document.createElement("a");
                const fileName = `${vm.type.name} - ${vm.budget.created_at} - ${vm.budget.client}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }).catch(er => {
                console.log('er',er)
                vm.toast(`error downloadPDF: ${er}`, 'error')
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

</style>