<template>
    <div>
        <!-- TITLE + ADD ROW -->
        <v-row class="mb-3" no-gutters align="center" v-if="title">
            <v-col :cols="noAddRows ? 12 : 11" class="label">
                {{ title }}
            </v-col>
            <v-col v-if="!noAddRows" cols="1" align="center">
                <v-btn icon color="primary" @click="addRow()">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <!-- INPUTS -->
        <div v-for="(item, i) in items" :key="i" class="mb-5">
            <v-row class="separator px-3" align="center">
                <v-col :cols="isFirstRow(i) || noAddRows || !canEditableRow(i) ? 12 : 11" class="px-0 pb-0">
                    <kimsa-text-field
                        no-border
                        :input-label="nameLabel"
                        outlined flat dense force-value
                        :value="items[i].name"
                        @change="items[i].name = $event"
                        :readonly="noAddRows || !canEditableRow(i)"
                    />
                </v-col>
                <v-col cols="1" v-if="!isFirstRow(i) && !noAddRows && canEditableRow(i)" align="center" class="pb-0">
                    <v-btn icon color="primary" @click="deleteRow(i)">
                        <v-icon>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="1" v-for="month in months" :key="month.value" class="px-0">
                    <kimsa-text-field
                        no-border
                        :input-label="month.name"
                        outlined dense force-value
                        :value="items[i][month.value]"
                        @change="items[i][month.value] = $event"
                        @blur="validNumber(items[i][month.value], i, month.value)"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";

export default {
    name: "MonthlyFinancialValuesInput",
    components: {KimsaTextField},
    props: {
        title: {
            type: String,
            default: ''
        },
        template: {
            type: Object,
            default: () => {}
        },
        values: {
            type: Array,
            default: () => []
        },
        nameLabel: {
            type: String,
            default: 'Activos',
        },
        noAddRows: {
            type: Boolean,
            default: false,
        },
        noDeleteRows: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            items: [],
            maxLength: [],
            months: [
                {name: 'Enero', value: 'january'},
                {name: 'Febrero', value: 'february'},
                {name: 'Marzo', value: 'march'},
                {name: 'Abril', value: 'april'},
                {name: 'Mayo', value: 'may'},
                {name: 'Junio', value: 'june'},
                {name: 'Julio', value: 'july'},
                {name: 'Agosto', value: 'august'},
                {name: 'Septiembre', value: 'september'},
                {name: 'Octubre', value: 'october'},
                {name: 'Noviembre', value: 'november'},
                {name: 'Diciembre', value: 'december'},
            ],
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
        vm.maxLength = vm.values?.length || 0
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.values && vm.values.length > 0) vm.items = vm.values
            else {
                vm.items = []
                vm.addRow()
            }
        },
        addRow() {
            const vm = this
            vm.items = JSON.parse(JSON.stringify(vm.items))
            vm.items.push(JSON.parse(JSON.stringify(vm.template)))
        },
        deleteRow(index) {
            const vm = this
            vm.items.splice(index, 1)
        },
        isFirstRow(i) {
            return i === 0
        },
        validNumber(value, index, attribute) {
            if (isNaN(Number(value))) {
                this.items[index][attribute] = 0
                this.items = JSON.parse(JSON.stringify(this.items))
            } else {
                this.items[index][attribute] = Number(value)
                this.items = JSON.parse(JSON.stringify(this.items))
            }
        },
        canEditableRow(i) {
            if(!this.noDeleteRows) return true
            return this.maxLength <= i
        }
    },
    watch: {
        items: {
            deep: true,
            handler() {this.$emit('change', this.items)}
        },
        values() {
            this.setDefaults()
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 12px;
    color: #212322;
    font-weight: bold;
}

.label-input {
    font-size: 10px;
    color: #212322;
}

.separator {
    border: 2px solid rgba(112, 112, 112, 0.5);
    border-radius: 4px;
}

</style>